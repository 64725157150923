

.specificimg {
    width: 220px;
    height: 290px;
    border: 3px gray solid;
}

.itemtext {
    font-size: 8pt;
    border: 2px black solid;
    margin: 0px;
    background-color: rgb(37, 124, 37);
}

.itemforsale {
    background-color: rgb(47, 155, 47);
    border: 3px solid blue;
    padding: 20px;
    margin-left: 50px;
    margin-bottom: 25px;
    margin-top: 25px;
    width: 166px;
}

.itemtype {
    margin: 0px;
    font-size: 15pt;
}

.dimensions {
    margin: 0px;
    font-size: 12pt;
}

.price {
    margin: 0px;
    font-size: 12pt;
}

.pricedimrow {
    padding-top: 7px;
}

.addtocartbutton {
    border: 4px solid black;
    font-size: 18pt;
    width: 60px;
    height: 60px;
    margin-bottom: 15px;
}

.filter {
    background-color: rgb(80, 80, 80);
    color: white;
    width: 120px;
    padding: 8px;
    margin-top: 25px;
}

.shopbyitemtitle {
    color: white
}

.itemdescription {
    color: white;
    font-size: 15pt;
}

.minusbuttonpage {
    margin-right: 20px;
    margin-left: 20px;
    font-size: 20pt;
}

.plusbuttonpage {
    margin-left: 20px;
    font-size: 20pt;
}

.itempagephoto {
    width: 530px;
    height: 670px;
}

.cartbuttonpage {
    width: 100px;
    height: 100px;
    margin-left: 20px;
    margin-top: 50px;
    font-size: 40pt;
}

.backbutton {
    height: 50px;
    width: 200px;
    margin-left: 300px;
    font-size: 18pt;
    margin-bottom: 20px;
}

.specificimgbtn {
    padding: 0px;
}

.plusbuttonshop {
    width: 30px;
    height: 30px;
    font-size: 12pt;
    padding: 0px;
    border: 2px black solid;
}

.minusbuttonshop {
    width: 30px;
    height: 30x;
    font-size: 12pt;
    padding: 0px;
    border: 2px black solid;
}

.quantityshop {
    font-size: 14pt;
    padding-left: 5px;
    padding-right: 5px;
    color: blue;
}

.changequantityshop {
    margin-top: 13px;
}