.aliengazebologo {
    width: 120px;
    height: 150px;
    margin-left: 30px;
    margin-right: 300px;
    font-family: 'Zen Dots', cursive;
}

.aliengazebotitle {
    background-color: black;
    color: lightgreen;
    width: 800px;
    margin-left: 220px;
    padding: 15px;
}

.dropdownlink {
    font-size: 18pt;
    color: rgb(9, 62, 9);
}

.headernavlink {
    font-size: 27pt;
}

.headernavdropdown {
    font-size: 27pt;
    color: white;
}

.navlink {
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 10px;
}