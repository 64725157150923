@font-face {
    font-family: "ZenDotsRegular";
    src: local("ZenDotsRegular"),
      url("./fonts/ZenDots-Regular.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "MontserratMedium";
    src: local("MonserratMedium"),
      url("./fonts/Montserrat-Medium.ttf") format("truetype");
    font-weight: normal;
}