.name {
    font-size: 36pt;
    color: yellowgreen;
    margin-top: 35px;
    font-family: 'Montserrat', sans-serif;
}

.email {
    font-size: 30pt;
    color: rgb(240, 49, 49);
    font-family: 'Montserrat', sans-serif;
}

.cellphone {
    font-size: 28pt;
    color: orange;
    font-family: 'Montserrat', sans-serif;
}

.medialink {
    width: 80px;
    height: 80px;
    margin-bottom: 50px;
    flex: 1;
}

.linksdiv {
    display: flex;
}

.youtubelink {
    margin-left: 360px;
}

.instagramlink {
    margin-left: 100px;
}

.facebooklink {
    margin-left: 100px;
}

.soundcloudlink {
    margin-left: 100px;
}