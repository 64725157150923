.cart {
    color: white;
    font-size: 14pt;
}

.cartimage {
    width: 70px;
    height: 100px;
}

.cartrow {
    margin-top: 15px;
    margin-bottom: 15px;
}

.checkoutbutton {
    margin-left: 440px;
    width: 200px;
    height: 60px;
    margin-bottom: 50px;
    font-size: 18pt;
}

.totalpricecart {
    padding-left: 850px;
}

.checkouticon {
    font-size: 23pt;
    margin-right: 15px;
}